.adminExit {
	position: fixed;
	top: 0;
	right: 10px;
	z-index: 10001;
	background: #fff;
	border-radius: 4px;
	width: 30px;
	height: 30px;
	box-shadow: 0 2px 2px rgba(#000, .2);
	
	&:before, &:after {
		content: '';
		position: absolute;
		left: 5px;
		right: 5px;
		height: 1px;
		background: red;
		top: 50%;
		transform: rotate(45deg);
	}
	
	&:after {
		transform: rotate(-45deg);
	}
}