.coreIFramePopup {
	position: fixed;
	margin: 0;
	padding: 0;
	z-index: 10000000;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	
	iframe {
		margin: 0;
		padding: 0;	
		width: 100%;
		height: 100%;
	}
}