.coreMenuAdmin {
	border-radius: 3px;
	border: 1px solid red;	
	position: relative;
}

.coreMenuAdmin-btn {
	position: absolute;
	right: -1px;
	top: -1px;
	border: 1px solid red;
	border-radius: 3px;
	font-size: 16px / 16px * 1rem;
	background: #fff;
	padding: 0 4px;
	color: #000;
	cursor: pointer;
}

.coreMenuAdmin-overlay {
	font-size: 16px / 16px * 1rem;
	font-family: Arial, sans-serif;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10001;
	background: rgba(#fff, 1);
	padding: 10px 40px 30px;
	box-shadow: 0 0 10px rgba(#000, 0);
	transition: .2s;
}

.coreMenuAdmin-content {
	
}

.coreMenuAdmin-list {
	
}

.coreMenuAdmin-add {
	border-top: 1px solid #aaa;
	margin-top: 30px;
}

.coreMenuAdmin-pages {
	max-height: 500px;
	overflow: auto;
}
