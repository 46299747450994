.adminTop {
	font-size: 16px / 16px * 1rem;
	font-family: Arial, sans-serif;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 10000;
	background: rgba(#fff, .95);
	padding: 10px 40px 30px;
	transform: translateY(-100%);
	box-shadow: 0 0 10px rgba(#000, 0);
	transition: .2s;
}

.adminTop-ctrl {
	position: absolute;
	right: 50px;
	bottom: 0;
	transform: translateY(100%);
	box-shadow: 0 2px 2px rgba(#000, .2);
	background: #fff;
	width: 30px;
	height: 30px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border: none;
	padding: 0;
	
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		border: 1px solid #000;
		width: 10px;
		height: 10px;
		transform: translateY(-50%) translateX(-50%) rotate(45deg);
		border-top: none;
		border-left: none;
	}
}

.adminTop_visible {
	transform: translateY(0);
	box-shadow: 0 0 10px rgba(#000, .2);
	.adminTop-ctrl {
		&:after {
			transform: translateY(-50%) translateX(-50%) rotate(-135deg);
		}
	}
}