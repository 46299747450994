.adminTable {
	border-collapse: collapse;
	td, th {
		vertical-align: top;
	}
}

.adminTable_form {
	td, th {
		vertical-align: middle;
		padding: 2px 8px;
	}
	th {
		text-align: right;
		padding-right: 10px;
		padding-left: 0;
	}
	td {
		
	}
}